
import { defineComponent, toRefs } from "vue";
import useDelearNo from "@/hooks/payment/bu/useDelearNo";
import TableWrapper from "../../components/TableWrapper.vue";

export default defineComponent({
  name: "DealerNoOverview",
  props: {},
  setup(props, context) {
    const {
      column,
      total,
      tableData,
      query,
      _query,
      search,
      reset,
      getRowClass,
      options,
      pagination,
      pageChange,
      sizeChange,
    } = useDelearNo();

    return {
      column,
      total,
      tableData,
      query,
      _query,
      search,
      reset,
      getRowClass,
      ...toRefs(pagination),
      ...toRefs(options),
      pageChange,
      sizeChange,
    };
  },
  components: {
    TableWrapper,
  },
});
