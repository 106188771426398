import { computed, onMounted, reactive, ref, Ref, toRaw } from "vue";
import { useRoute } from 'vue-router'
import { ColumnProps } from "ant-design-vue/es/table/interface";
import useMock from "@/utils/payment/useMock";
import useStripedRowClass from '@/utils/payment/useStripedRowClass'
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal"
import useThousands from '@/utils/payment/useThousands'
import Data from "@/views/Payment/BU/DealerNoOverview/mock";
import { DataProps } from "@/views/Payment/BU/DealerNoOverview/type";
import { getDealerDataList, getListDealerNumber } from '@/API/payment'
import { getBuList } from '@/API/approvalChain'
import useWait from '@/utils/payment/useWait'
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
const useDealerNo = () => {
  // 表头
  const column = ref([
    {
      title: "Dealer Code",
      dataIndex: "dealerCode"
    },
    {
      title: "Dealer Name",
      dataIndex: "dealerName",
      width: 280,
    },
    {
      title: "Dealer Group",
      dataIndex: "dealerGroup",
    },
    {
      title: "Dealer Region",
      dataIndex: "dealerRegion",
      width: 180
    },
    {
      title: "Vin No.",
      dataIndex: "vinNo",
      customRender: useThousands(),
      width: 120,
      align: "right"
    },
    {
      title: "Payout Amount w/o VAT",
      dataIndex: "payoutAmountWOVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right"
    },
    {
      title: "Payout Amount w VAT",
      dataIndex: "payoutAmountWVat",
      width: 200,
      customRender: useThousandsAndTwoDecimal(),
      align: "right"
    },
  ]);
  
  // 配置信息
  const options = reactive({
    isPagination: true,
    isTabBar: false,
    searchRowNum: 1
  })
  // 查询条件
  const query = reactive({
    paymentProgramId: '',
    paymentOverviewId: '',
    dealerName: '',
    dealerCode: ''
  });
  const _query = {
    programCode: '',
    programName: '',
    payoutRound: "",
    payoutBatch: "",
  }
  const queryActive = reactive({
    dealerName: '',
    dealerCode: ''
  })
  // 分页
  const pagination = reactive({
    pageNumber: 1,
    pageSize: 10,
  });
  // 全量数据
  const allData = ref<any>([])
  const wait = useWait()

  // 初始化从获取路由参数并填充query
  const route = useRoute()
  _query.programCode = route.query.programCode as string ? route.query.programCode as string : ''
  _query.programName = route.query.programName as string ? route.query.programName as string : ''
  _query.payoutRound = route.query.payoutRound as string ? route.query.payoutRound as string : ''
  _query.payoutBatch = route.query.payoutBatch as string ? route.query.payoutBatch as string : ''
  const id = route.query.id as string ? route.query.id as string : ''
  if (_query.programCode || _query.programName) {
    query.paymentProgramId = id
    query.paymentOverviewId = ''
  } else {
    query.paymentProgramId = ''
    query.paymentOverviewId = id
  }

  const store = useStore()

  const init = () => {
    // 获取排序dealer数据
    let bu: any = null
    const getOrderData = getBuList().then((buList: unknown) => {
      bu = (buList as any[]).find(item => item.nameEn === store.state.user?.bu.orgnmen)
      if (!bu) {
        message.error('Can not find current bu!')
        return Promise.reject()
      }
      return bu.id
    }).then((buId: number) => getDealerDataList(buId))
    Promise.all([
      getOrderData,
      getListDealerNumber({
        params: {
          paymentProgramId: query.paymentProgramId,
          paymentOverviewId: query.paymentOverviewId
        }
      })
    ]).then(([orderData, data]: any[]) => {
      const order = orderData.map((item: any) => item.dealerInfo.dealerCode)
      allData.value = (data as unknown as any[]).sort((a: any, b: any) => {
        const indexA = order.indexOf(a.dealerCode)
        const indexB = order.indexOf(b.dealerCode)
        if (indexA === -1) {
          return 1
        } else if (indexB === -1) {
          return -1
        } else {
          return indexA - indexB
        }
      })
      allData.value.map((item: any) => {
        const _index = order.indexOf(item.dealerCode)
        item.dealerName = orderData[_index].dealerInfo.dealerNameEn
        item.dealerGroup = orderData[_index].dealerGroup.dealerGroupEn
        item.dealerRegion = orderData[_index]?.dealerRegion.find((region: any) => region.buId === bu.id.toString())?.subRegionEn
      })
      if (wait.toRun) {
        wait.toRun()
      }
      
    })
  }

  const search = () => {
    wait.then(() => {
      queryActive.dealerCode = query.dealerCode
      queryActive.dealerName = query.dealerName
      pagination.pageNumber = 1
      pagination.pageSize = 10
    })
  };
  const pageChange = (val: number) => {
    pagination.pageNumber = val;
  };
  const sizeChange = (current: number, size: number) => {
    pagination.pageSize = size;
  };
  init();
  const reset = () => {
    query.dealerName = ''
    query.dealerCode = ''
    pagination.pageNumber = 1
    pagination.pageSize = 10
    search();
  }

  const filterData = computed(() => {
    return allData.value.filter((item: any) => {
      return item.dealerName.includes(queryActive.dealerName) && item.dealerCode.includes(queryActive.dealerCode)
    })
  })

  const total = computed(() => {
    return filterData.value.length
  })

  const tableData = computed(() => {
    const begin = pagination.pageSize * (pagination.pageNumber - 1)
    const end = begin + pagination.pageSize
    return filterData.value.slice(begin, end)
  });
  // 斑马纹
  const getRowClass = useStripedRowClass<DataProps>()
  return {
    column,
    total,
    tableData,
    query,
    _query,
    search,
    reset,
    getRowClass,
    options,
    pagination,
    pageChange,
    sizeChange
  };
};
export default useDealerNo;
