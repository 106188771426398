const useStripedRowClass = <T>() => {
  return (record: T, index: number) => {
    if (index % 2 === 1) {
      return "table-striped";
    } else {
      return null;
    }
  };
};
export default useStripedRowClass;
